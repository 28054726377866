<template>
  <div id="jci-trainers-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>

        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="submitForm"
        >
          <v-container v-if="RecordFlag">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Membership Id</h5>
                <p class="preview-content">{{ row.MembershipId }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Full Name</h5>
                <p class="preview-content">{{ row.FullName }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Trainer Type Name</h5>
                <p class="preview-content">{{ row.TrainerTypeName }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Trained Date</h5>
                <p class="preview-content">{{ row.TrainerDate }}</p>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Created Date</h5>
                <p class="preview-content">{{ row.CreatedTxt }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Responed Date</h5>
                <p class="preview-content">{{ row.UpdatedTxt }}</p>
              </v-col>

              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Attachments</h5>
                <!-- <template v-slot:row.ImagePath="{ item }"> -->
                <viewer rebuild :images="row.AllImages">
                  <img
                    style="padding: 5px"
                    width="100"
                    height="100"
                    :src="src"
                    :key="src"
                    v-for="src in row.AllImages"
                  />
                </viewer>
                <!-- </template> -->
              </v-col>
            </v-row>
            <br />
            <v-row wrap v-if="row.ActiveStatus == 2 || row.ActiveStatus == 3">
              <v-col cols="12">
                <hr />
              </v-col>
              <v-col align="center" cols="12">
                <h5 class="text-danger"><span> * </span>For Admin Only</h5>
              </v-col>
              <v-col cols="12" sm="5" lg="5" md="5">
                <h6>Description <small>(optional)</small></h6>
                <v-text-field
                  v-model="Description"
                  placeholder="Description"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col align="center" col="12" sm="12" lg="12" md="12">
                <v-btn
                  @click.prevent="confirmAlert(1)"
                  :loading="ApproveLoadingFlag"
                  color="success"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Approve
                </v-btn>
                <v-btn
                  @click.prevent="confirmAlert(0)"
                  :loading="ApproveRejectLoadingFlag"
                  color="warning"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Reject
                </v-btn>
                <v-btn
                  @click="closePrompt"
                  color="primary"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
            <br />
          </v-container>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions v-if="previewRecordFlag" align="center">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
        </v-card-actions> -->
    </v-card>
  </div>
</template>
    
    <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordFlag: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      Width: 500,

      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      ResultFlag: false,
      RecordFlag:false,
      LoadingFlag: false,
      row: {},
      PaymentDetails: {},
      selected: [],

      Description: "",

      ApprovedDate: new Date().toISOString().slice(0, 10),
      menu1: false,

      ApproveLoadingFlag: false,
      ApproveRejectLoadingFlag: false,
      ConfirmationLoadingFlag: false,
      ConfirmationRejectLoadingFlag: false,

      dialog: false,
      dialog1: false,

      tableColumns: [],
      tableOptions: [],
      tableData: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var TrainerId = this.recordData.TrainerId;
      var UserInterface = 1;
      console.log(", TrainerId=" + TrainerId);

      if (UserInterface != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/trainer-details/show";
        var upload = {
          UserInterface: UserInterface,
          TrainerId: TrainerId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.row = {};
        this.RecordFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.Documentpath = "";

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.RecordFlag = flag;

            if (flag == 1) {
              thisIns.row = records;
              console.log("records=" + JSON.stringify(records));
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
    confirmAlert(status) {
      console.log("confirmAlert is called");
      console.log({ status });
      var htmlTxt = "";

      //   var validate = this.$refs.form1.validate();
      var validate = true;
      console.log({ validate });

      if (validate) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            switch (status) {
              case 0:
                this.approvalSubmitForm(0);

                break;

              case 1:
                this.approvalSubmitForm(1);

                break;

              default:
                break;
            }
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      }
    },
    approvalSubmitForm(status) {
      console.log("approvalSubmitForm is called");

      console.log({ status });

      var tr = this.row;
      console.log({ tr });

      this.ProgressLoadingFlag = true;
      this.ResultFlag = false;

      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var TrainerType = tr.TrainerTypeId;
      var TrainerId = tr.TrainerId;
      var MemberId = tr.MemberId;
      var Description = this.Description;

      console.log({ TrainerType });
      console.log({ TrainerId });
      console.log({ MemberId });
      console.log({ Description });

      var server_url = companyConfig.apiURL;
      var add_url = server_url + "api/trainer-details/approval";
      console.log({ add_url });

      var upload = {
        UserInterface: 1,
        TrainerId: TrainerId,
        TrainerType: TrainerType,
        MemberId: MemberId,
        Description: Description,
        Status: status,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;
          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.ResultFlag = true;
            thisIns.closePrompt();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.ProgressLoadingFlag = true;
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
    <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#events-preview {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>